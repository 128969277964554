import React from "react";
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Layout from "../components/layout"
import Seo from "../components/seo"

const title = "Virtual Market 3 \"NEO SHIBUYA\""
const scope = "VR空間体験設計・制作"
const str1 = "「バーチャルマーケット3」の会場「ネオ渋谷」の空間体験ディレクション、制作チームの一員として主要部の制作を行った。"
const str2 = "渋谷を渋谷たらしめている要素を抽出し再構成するとともに、ハチ公像や青蛙やひばり号といった渋谷の過去・現在を彩るランドマーク・交通に新たに命を吹き込むことで、低負荷かつ祝祭的な都市の姿を実現している。"

const settings = {
  arrows: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  pauseOnHover: false,
}

export default ({ data, location }) => (
      <Layout>
        <Seo
          pagetitle={title}
          pagedesc={str1}
          pagepath={location.pathname}
          pageimg={data.neo01.childImageSharp.original.src}
          pageimgw={data.neo01.childImageSharp.original.width}
          pageimgh={data.neo01.childImageSharp.original.height}
        />
        <div className="nav">
          <Link to = {`/works/`}>
            <svg className="switchButton" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.41 52.83">
              <g id="レイヤー_2" data-name="レイヤー 2"><g id="UI"><path d="M98,50c-.61.38-1.28.06-6.25-2.49L50.65,26.42,91.74,5.29c5-2.56,5.78-2.95,6.3-2.52l.21.18a4.41,4.41,0,0,0,1.16-.62,6.42,6.42,0,0,1-.73-1.08A6,6,0,0,1,98.21,0,4.78,4.78,0,0,0,97,.58l0,.28c0,.68-.74,1.1-5.71,3.66L49.71,25.93,8.06,4.52C3.09,2,2.31,1.54,2.35.86l0-.28A4.78,4.78,0,0,0,1.2,0,6,6,0,0,1,.73,1.25,6.42,6.42,0,0,1,0,2.33,4.58,4.58,0,0,0,1.16,3l.22-.18c.51-.43,1.32,0,6.29,2.52L48.76,26.42,7.67,47.55C2.7,50.1,2,50.42,1.42,50l-.26-.16A4.44,4.44,0,0,0,0,50.5a6.11,6.11,0,0,1,.74,1.11,5.89,5.89,0,0,1,.46,1.22,4.45,4.45,0,0,0,1.18-.58l0-.3c.05-.72.69-1.08,5.66-3.64L49.71,26.9,91.35,48.31C96.32,50.87,97,51.23,97,52l0,.3a4.45,4.45,0,0,0,1.18.58,5.89,5.89,0,0,1,.46-1.22,6.11,6.11,0,0,1,.74-1.11,4.44,4.44,0,0,0-1.16-.62Z" fill="black"/></g></g>
            </svg>
          </Link>
        </div>
        <div id="pageBody" className="wrapper">
          <Slider {...settings}>
            <div className="full">
              <StaticImage className="cover" src="../images/neo01.jpg" alt="" />
            </div>
            <div className="full">
              <StaticImage className="cover" src="../images/neo02.jpg" alt="" />
            </div>
            <div className="full">
              <StaticImage className="cover" src="../images/neo03.jpg" alt="" />
            </div>
            <div className="full">
              <StaticImage className="cover" src="../images/neo04.jpg" alt="" />
            </div>
            <div className="full">
              <StaticImage className="cover" src="../images/neo05.jpg" alt="" />
            </div>
          </Slider>
          <div className="title">
            <h4>{title}</h4>
            <p><span>{scope}</span></p>
            <br />
            <p>{str1}</p>
            <p>{str2}</p>
          </div>
        </div>
      </Layout>
)

export const query = graphql`
query {
  neo01: file(relativePath: {eq: "neo01.jpg"}) {
    childImageSharp {
      original {
        height
        src
        width
      }
    }
  }
}
`
